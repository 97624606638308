import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let today = new Date();

    $(".datepicker").datepicker({
      language: "de",
      format: "dd.mm.yyyy",
      weekStart: 1,
      autoclose: true,
      startDate: today
    });
  }
}
