import noUiSlider from 'nouislider'
import dialogPolyfill from 'dialog-polyfill'

export const showFilterModal = (dialog) => {
  if (!dialog) return

  dialogPolyfill.registerDialog(dialog)
  dialog.showModal()

  if (!dialog.classList.contains("initialized")) {
    dialog.classList.add("initialized")

    // Setup deletable job category filter bubbles
    dialog.addEventListener("click", removePill)
    dialog.addEventListener("click", (event) => {
      if (event.target == dialog) {
        dialog.close()
      }
    })
  }
  // Setup min/max slider
  const lower = document.getElementById("min_workload")
  const upper = document.getElementById("max_workload")

  initPensumRangeSlider(dialog.querySelector(".filter__range"), lower, upper)
}

export const addCategoryFilterOption = (selectElem) => {
  const option = selectElem.querySelector(`option[value='${selectElem.value}']`)
  const value = selectElem.value
  const label = option.text
  const parent = selectElem.parentNode

  if (value === "") {
    // When the "All" option was chosen, remove all options again
    resetPills(parent)
  } else {
    // Only add if the pill for this option is not already present
    if (parent.querySelectorAll(`input[type="hidden"][value="${value}"]`).length === 0) {
      // This DOM build should be aligned with what is rendered server side in _filters.html.haml
      const optionPill = `<span class="btn btn-tag btn-sm rounded-pill text-truncate rounded-2 px-3 mb-2">${label} ✕<input type="hidden" name="job_categories[]" value="${value}"></span>`
      selectElem.insertAdjacentHTML("beforebegin", optionPill)
    }
  }
}

export const resetFilter = (button) => {
  const elements = button.closest("form").elements

  for (let i = 0; i < elements.length; i++) {
    switch (elements[i].type.toLowerCase()) {
      case "text":
      case "password":
      case "textarea":
      case "hidden":
        elements[i].value = ""
        break

      case "radio":
      case "checkbox":
        if (elements[i].checked) {
          elements[i].checked = false
        }
        break

      case "select-one":
      case "select-multi":
        elements[i].selectedIndex = 0
        break
      default:
        break
    }
  }

  resetPills(button.form)
  resetRange(button.form)
}

const removePill = (event) => {
  // Delete active filter option
  if (event.target.classList.contains("rounded-pill")) {
    const form = event.target.closest("form")
    event.target.remove()
  }
}

const resetPills = (parent) => {
  parent.querySelectorAll(".rounded-pill").forEach(e => e.remove())
}

const resetRange = (parent) => {
  const defaultMin = 0
  const defaultMax = 100

  parent.querySelector(".filter__range").noUiSlider.set([defaultMin, defaultMax])
  document.getElementById("min_workload").value = defaultMin
  document.getElementById("max_workload").value = defaultMax
}

const initPensumRangeSlider = (elem, lowerInputElem, upperInputElem) => {
  const slider = elem

  if (elem.classList.contains("initialized")) return

  // Docs: https://refreshless.com/nouislider/
  noUiSlider.create(slider, {
    start: [parseInt(lowerInputElem.value), parseInt(upperInputElem.value)],
    connect: true,
    step: 10,
    range: {
      'min': 0,
      'max': 100
    }
  }).on("change", ([min, max]) => {
    // Prevent fractional values like 0.54 or 99.43
    lowerInputElem.value = parseInt(min)
    upperInputElem.value = parseInt(max)
  })

  elem.classList.add("initialized")
}
