import { debounce } from "./utils"
import { MarkerClusterer } from "@googlemaps/markerclusterer"
import mapStyles from "./map-styles"

let map // google.maps.Map instance
let markers // array of all markers created on the first load
let clusterer // MarkerClusterer instance
let jobIdCache = {} // jobIdCache[String(jobId)] = google.maps.Marker
let detailWindow // google.maps.InfoWindow instance
let currentDetailMarker // google.maps.Marker instance
let customMarkerIcon // style object for custom marker
let customMarkerActiveIcon // style for active custom marker

// `jobIds` is a the list of all jobs that need to be displayed as a marker
const updateList = debounce(() => {
  const mapBounds = map.getBounds()
  const { east, west, south, north } = mapBounds.toJSON()

  // Update the form values for the "jobs" turbo-frame
  // and submit it. The server can then filter
  // the list given the edge coordinates of the map.
  window.north.value = north
  window.east.value = east
  window.south.value = south
  window.west.value = west
  window.west.form.requestSubmit()
}, 500)


const displayMarkersIfWithinBounds = (mapBounds, jobIds) => {
  for (const [jobId, marker] of Object.entries(jobIdCache)) {
    const isWithinBounds = mapBounds.contains(marker.getPosition())
    const isPartOfJobIds = jobIds.indexOf(jobId) !== -1

    if (isWithinBounds && isPartOfJobIds) {
      clusterer.addMarker(marker)
    } else {
      clusterer.removeMarker(marker)
    }
  }
}

const createMarkers = (locations, collection = []) => {
  customMarkerIcon = {
    url: document.querySelector("img[data-job-marker]").src,
    scaledSize: new google.maps.Size(44, 54),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(22, 48) // anchor at the lower tip of the yellow dropplet
  }

  customMarkerActiveIcon = {
    url: document.querySelector("img[data-job-marker-active]").src,
    scaledSize: new google.maps.Size(44, 54),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(22, 48) // anchor at the lower tip of the yellow dropplet
  }

  for (const [location, jobIds] of locations) {
    const [lat, lng] = location.split(",")
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
      label: { text: String(jobIds.length), color: "black", fontSize: "10px", className: "custom-marker-label" },
      icon: customMarkerIcon,
      jobIds: jobIds,
    })

    collection.push(marker)

    // Create a lookup table for each jobId, so it's easy to
    // decide in #refreshMarkers if a marker needs to be displayed or not
    jobIds.forEach(jobId => jobIdCache[String(jobId)] = marker)

    // When the marker is clicked, we load the job details into the turbo-frame with the dom ID "location-detail-frame"
    marker.addListener("click", () => loadLocationDetail(marker, jobIds))
  }

  return collection
}

const loadLocationDetail = (marker, jobIds) => {
  const detailElem = document.getElementById("location-detail-frame")

  const jobParams = new URLSearchParams()
  jobIds.forEach(jobId => jobParams.append("job_id[]", jobId))

  detailElem.src = `/jobs/details?${jobParams.toString()}`
  resetActiveMarker()
  currentDetailMarker = marker
  currentDetailMarker.setIcon(customMarkerActiveIcon)
}

const closeLocationDetail = () => {
  if (detailWindow) {
    resetActiveMarker()
    detailWindow.close()
  }
}

const resetActiveMarker = () => {
  if (currentDetailMarker) currentDetailMarker.setIcon(customMarkerIcon)
}

const renderer = {
  render: ({ count, position }) =>
    new google.maps.Marker({
      label: { text: String(count), color: "white", fontSize: "10px" },
      position,
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      icon: {
        url: document.querySelector("img[data-job-marker-cluster]").src,
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(30, 30) // anchor at the lower tip of the yellow dropplet
      },
    }),
};

export const initMap = () => {
  const params = new URLSearchParams(window.location.search)
  const { north, east, south, west } = Object.fromEntries(params.entries())

  map = new google.maps.Map(document.getElementById("map"), {
    styles: mapStyles
  })
  detailWindow = new google.maps.InfoWindow({ map, disableAutoPan: true })
  detailWindow.addListener("closeclick", resetActiveMarker)

  if (north && east && south && west) {
    const restoreBounds = {
      north: parseFloat(north),
      east: parseFloat(east),
      south: parseFloat(south),
      west: parseFloat(west),
    }
    map.fitBounds(restoreBounds, 0)
  } else {
    map.setCenter(window.portalCenterPoint)
    map.setZoom(window.portalZoom)
  }

  // window.jobLocations contains all jobs locations possible
  // and is rendered in the initial load in jobs#index
  // We create Marker objects for each job location and cache them in `markers`
  markers = createMarkers(window.jobLocations)

  // Create clusters for markers which are close
  clusterer = new MarkerClusterer({ map, markers: [], renderer })

  // Whenever the map is moved or the window is resized, it's bounds are changed
  // The list of jobs should update and display all jobs
  // that are shown inside the map bounds
  map.addListener("bounds_changed", updateList)
  map.addListener("zoom_changed", closeLocationDetail)
}

// Called when a list item is hovered on the jobs overview page
export const highlightMarkerByJobId = (jobId) => {
  const marker = jobIdCache[jobId]

  if (marker) {
    marker.setIcon(customMarkerActiveIcon)
  }
}

export const deselectMarkerByJobId = (jobId) => {
  const marker = jobIdCache[jobId]

  if (marker) {
    marker.setIcon(customMarkerIcon)
  }
}

// When `updateList` is called, we call `refreshMarkers` to only display
// Markers which are in the filtered result set.
// `locations` argument is always a filtered subset of what we have in `window.jobLocations`
// This way we only show markers which are also part of the result set
export const refreshMarkers = (locations) => {
  const jobIds = locations.flatMap(entry => entry[1])
  displayMarkersIfWithinBounds(map.getBounds(), jobIds)
}

// When the "location-display-frame" turbo-frame is loaded
// this method is called to populate the InfoWindow on the map
// with all jobs for the clicked location
export const displayLocationDetail = () => {
  detailWindow.open({
    anchor: currentDetailMarker
  })

  detailWindow.setContent(document.getElementById("location-detail"))
}
