import noUiSlider from 'nouislider'

const initPensumRangeSlider = (elem, lowerInputElem, upperInputElem) => {
  const slider = elem[0]

  // if (elem.classList.contains("initialized")) return

  // Docs: https://refreshless.com/nouislider/
  noUiSlider.create(slider, {
    start: [parseInt(lowerInputElem.value), parseInt(upperInputElem.value)],
    connect: true,
    step: 10,
    range: {
      'min': 0,
      'max': 100
    }
  }).on("change", ([min, max]) => {
    // Prevent fractional values like 0.54 or 99.43
    lowerInputElem.value = parseInt(min)
    upperInputElem.value = parseInt(max)
    const displayElem = document.getElementById("workload-display")
    displayElem.textContent = "Ausgewähltes Pensum: " + parseInt(min) + "% – " + parseInt(max) + "%"
  })

}

function jobHandler() {
  $('.description-type input[type=radio]').on('change', function() {
    $('.additional-content').addClass('hide');
    $(this).siblings('.additional-content').removeClass('hide');
  });

  $('.lead-type input[type=radio]').on('change', function() {
    $('.additional-content').addClass('hide');

    $('#btn-publication').val($(this).data('btn-name'));
    // } else {
    //   $('#btn-publication').val('');
    // }
    $(this).siblings('.additional-content').removeClass('hide');
  });

  if($('#job_workload_type').length != 0) {
    const lower = document.getElementById("job_min_workload")
    const upper = document.getElementById("job_max_workload")

    initPensumRangeSlider($(".filter__range"), lower, upper)
  }

  $('#job_workload_type').on('change', function() {
    if($(this).val() == 'halftime') {
      $('#workload-type-halftime').removeClass('d-none');
    } else {
      $('#workload-type-halftime').addClass('d-none');
    }
  });

  $('#job_alternative_location').on('change', function() {
    var checkbox = document.getElementById("job_alternative_location");

    if (checkbox.checked == true) {
      $("#alternative-location-wrapper").removeClass('d-none');
    } else {
      $("#alternative-location-wrapper").addClass('d-none');
    }
    // if($(this).checked == true) {
    //   alert('checked');
    //   // $('#workload-type-halftime').removeClass('d-none');
    // } else {
    //   alert($(this).val());
    //   // $('#workload-type-halftime').addClass('d-none');
    // }
  });
}

document.addEventListener('turbo:render', jobHandler);
document.addEventListener('DOMContentLoaded', jobHandler);
