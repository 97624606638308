// Entry point for the build script in your package.json
require("bootstrap-datepicker/js/locales/bootstrap-datepicker.de.js")

import "@hotwired/turbo-rails"
import './add_jquery'
import * as bootstrap from "bootstrap"
import "trix"
import "@rails/actiontext"

import * as map from "./map"
import * as filters from "./filters"
import "./upload_component"
import "./job_configurator"

import * as datepicker from "bootstrap-datepicker"

window.app = { filters, map }

// NOTE: window.initMap is referenced in the URL to the google maps sdk
// in the application layout (application.html.erb).
// Change it in the URL as well if you change the name here
window.initMap = app.map.initMap

import "./controllers"
